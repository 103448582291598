import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";
import parse from "html-react-parser";

import IconBox from "../components/IconBox/IconBox";
import "../styles/translations.scss";
import Heading from "../components/Heading/Heading";

const features = [
  [0, "briefcase", "order-md-1 order-lg-0"],
  [1, "edit", "order-md-0"],
  [3, "comments", "order-md-1"],
  [2, "globe", "order-md-1"],
  [4, "spell-check", "order-md-1"]
];

function Translations() {
  const { t } = useTranslation();
  return (
    <>
      <Container fluid className="pt-0 pt-sm-4 pb-1 py-lg-5 bg--light px-0" id="translate">
        <Container className="pt-4 pb-0 pt-lg-0 pb-lg-4 px-0">
          <Row className="m-0">
            <Heading>
              <h2 className="text-light m-0">
                {parse(t("translation.title"))}
              </h2>
            </Heading>
          </Row>
          <Row className="m-0">
            <Col className="col-lg-6 100-vw px-4 pb-4 pb-sm-5 pt-2 pb-md-4 py-lg-4" data-sal="slide-up" data-sal-delay="200">
              <p className="px-0 px-sm-4  px-lg-0 pr-lg-3">
                {parse(t("translation.content"))}
              </p>
            </Col>
            <Col className="col-12 col-lg-6 p-0 d-flex" data-sal="slide-right" data-sal-delay="200">
              <div className="col px-5 py-5 text--light bg--primary mt-auto">
                <h4 className="text-uppercase font-weight-bold text--info mb-2">{t("cta.valuation")}</h4>
                <p className="text--info m-0">{parse(t("cta.content"))}</p>
              </div>
            </Col>
          </Row>
          <Row className="iconBoxes m-0">
            <Col className="col-12 col-lg-12 px-0 px-lg-0 order-1 order-lg-2 d-flex flex-wrap">        
              {features.slice(0,3).map(feature => (
                <IconBox key={feature[0]} 
                icon={feature[1]}
                title={parse(t(`translation.features.${feature[0]}.title`))}
                description={parse(t(`translation.features.${feature[0]}.description`))}
                className={`col-12 col-md-6 col-lg-3 d-flex px-0 ${feature[2]}`}
                />
              ))}
              <Col className="col-12 col-md-6 col-lg-3 box-container px-0 order-1">
                {features.slice(3,features.length).map(feature => (
                  <IconBox key={feature[0]}
                  icon={feature[1]}
                  title={parse(t(`translation.features.${feature[0]}.title`))}
                  description={parse(t(`translation.features.${feature[0]}.description`))}
                  className="col-12 p-0 no-icon"
                  />
                ))}
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default withTranslation()(Translations);