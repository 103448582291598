import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import bg from "../images/bg.jpg";
import "../styles/top.scss";
import { withTranslation, useTranslation } from "react-i18next";
import parse from "html-react-parser";

function Top() {
  const { t } = useTranslation();
  return (
    <Container fluid className="p-0" id="home">
      <img src={bg} alt="background"/>
      <Container style={{width:"100%",position:"absolute",left:0,right:0,}}>
        <Row className="py-lg-5" style={{zIndex: 2}}>
          <Col className="col-12 col-md-12 col-lg-auto mb-auto pb-5 infoBox"  data-sal="slide-down" data-sal-delay="500">
            <h1 className="title">{parse(t("top.title"))}</h1>
            <p className="subtitle" data-sal="slide-right"  data-sal-delay="700">{t("top.subtitle")}</p>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
export default withTranslation()(Top);