import React, { useState } from "react";
import { Modal, Form as Form1, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { withTranslation, useTranslation } from "react-i18next";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import Recaptcha from "react-recaptcha";
import "./styles.scss";

library.add(faSpinner);
function ContactForm() {
const { t, i18n } = useTranslation();
const [showModal, setShowModal] = useState(false);
const [modalTitle, setModalTitle] = useState("");
const [modalContent, setModalContent] = useState("");
const fileMaxSize = 1024 * 1024 * 20;

function setModal(type) {
  setShowModal(true);
  setModalTitle(t(`contact.form.${type}.title`));
  setModalContent(parse(t(`contact.form.${type}.content`)))
}
  const setErrorText = (field) => {
    return t("contact.form.warning", {field:t(`contact.form.${field}`)});
  }
  return (
    <Formik
      validateOnBlur
      initialValues={{
        firstName: "",
        company: "",
        email: "",
        message: "",
        file: [],
        recaptcha: ""
      }}
      onSubmit={async (values, actions) => {
        const data = {
          method: "POST"
        }
        const formData = new FormData(); 
        const fileData = new FormData();
        
        Object.keys(values).map(key => (
          key === 'file' ? formData.append(key, values[key][0]) : formData.append(key, values[key])
        ));
        data.file = values.file.length ? fileData.append('file', values.file[0]) : "";
        await fetch(process.env.FORM_SUBMIT_URL, {
          method: "POST",
          body: formData,
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        })
        .then(() => {
          setModal("success")
          actions.resetForm()
        })
        .catch(() => {
          setModal("error");
        })
        .finally(() => actions.setSubmitting(false))
      }}
      validate={values => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const errors = {};
        if(!values.firstName) {
          errors.firstName = setErrorText("firstName");
        }
        if(!values.email || !emailRegex.test(values.email)) {
          errors.email = setErrorText("email");
        } 
        if(!values.message) {
          errors.message = setErrorText("message");
        }
        if(values.file.length > 0 && values.file[0].size > fileMaxSize) {
          errors.file = t("contact.form.fileError");
        }
        if(!values.recaptcha) {
          errors.recaptcha = "Error"
        }
        return errors;
      }}
      >
        {({values, errors, touched, setFieldValue, isSubmitting}) => (
          <>
        <Form name="contact-form" data-netlify={true} className="px-5 pt-2 pb-4 px-lg-4">
          <Form1.Row>
            <Col xs={12} lg={6}>
          <div className="form-group">
          <label htmlFor="firstName">{t("contact.form.firstName")}</label>
          <Field id="firstName" name="firstName" type="text" className="my-form-control"/>
          {errors.firstName && touched.firstName && <div className="d-block invalid-feedback">{setErrorText("firstName")}</div>}
          </div>
          
          <div className="form-group">
          <label htmlFor="company">{t("contact.form.company")}</label>
          <Field id="company" name="company" type="text" className="my-form-control"/>
          </div>
          <div className="form-group">
          <label htmlFor="email">E-Mail</label>
          <Field id="email" name="email" type="email" className="my-form-control"/>
          {errors.email 
          && touched.email 
          && <div className="d-block invalid-feedback">
            {setErrorText("email")}
          </div>}
          </div>
          </Col>
          <Col>
          <div className="form-group">
          <label htmlFor="message">{t("contact.form.message")}</label>
          <Field id="message" name="message" component="textarea" rows="7" className="my-form-control h-auto"/>
          {
            errors.message 
            && touched.message 
            && <div className="d-block invalid-feedback">
                {setErrorText("message")}
               </div>}
          </div>
          </Col>
          </Form1.Row>
          <Form1.Row>
            <Col lg={6}>
          <div className="form-group">
            <label htmlFor="file" className="bg--primary special">
              {t("contact.form.file")}
            <input 
              id="file"
              name="file"
              type="file"
              className="my-form-control"
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={(event) => {
                const newFile = [event.target.files[0]];
                setFieldValue("file", newFile)
                console.log(event.target.files[0]);
              }}
            />
              {
                values.file.length
                ? values.file.map((file, index) => (
                  <div key={index} className="file">
                    {file.name}
                    <button
                      type="button"
                      className="remove"
                      onClick={() => {
                        setFieldValue("file", []);
                      }}>x</button>
                    </div>
                ))
                : <p className="file">{t("contact.form.info")}</p>
              }
              </label>
              {errors.file && <div className="d-block invalid-feedback">{errors.file}</div>}
          </div>
          </Col>
          <Col lg={4}>
          <div className="form-group">
            {<Recaptcha
              sitekey="6Lfgv6UaAAAAAB536U8tUirgR6c1GzHYJXTBFgcg"
              render="explicit"
              theme="light"
              hl={i18n.language}
              verifyCallback={(response)=> { setFieldValue("recaptcha", response);}}
              onloadCallback={()=>{console.log("done")}}
            />}
          </div>
         
          <button type="submit" className="send">
            {t("contact.form.button.send")}
            {isSubmitting && <Icon className="ml-2" icon={faSpinner} spin />}
          </button>
          </Col>
          </Form1.Row>
        </Form>
        <Modal 
          show={showModal}
          onHide={()=>setShowModal(false)}
          centered
        >
          <Modal.Header closeButton className="bg--light">
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg--info text--light">{modalContent}</Modal.Body>
        </Modal>
        </>
        )}
    </Formik>
  )
}

export default withTranslation()(ContactForm);