import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Heading from "../components/Heading/Heading";
import { useTranslation, withTranslation } from "react-i18next";
import parse from "html-react-parser";
import ContactForm from "../components/ContactForm/ContactForm";
import person from "../images/germago-photo.png";

function About() {
  const { t } = useTranslation();
  return (
    <Container fluid className="bg--light pt-sm-0 pt-md-4 pt-lg-0 px-0" id="about">
      <Container className="p-0">
        <Row className="m-0">
          <Col className="col-12 col-lg-7 py-4 px-1 px-sm-3 pb-4 pb-sm-5 py-sm-5 py-lg-5 px-lg-0">
            <Heading>
              <h2 className="m-0">{t("about.title")}</h2>
            </Heading>
            <p className="px-5 pt-2 pb-3 p-lg-4 pt-lg-4 m-0" data-sal="slide-right" data-sal-delay="200">
              {parse(t("about.content"))}
            </p>
          </Col>
          <Col 
            className="d-md-none d-lg-flex col-12 col-lg-5 px-0 py-sm-4 pb-0 pb-lg-0 px-lg-0 bg--light text--light"
            data-sal="slide-right"
          >
            <div className="col-8 offset-2 offset-md-0 col-md-12 p-0 d-flex justify-content-end">
              <img src={person} alt="Anna Skrzypnik" class="m-0"/>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default withTranslation()(About);