import i18n from "i18next"
import Backend from "i18next-xhr-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import translationPL from "../locales/pl/translation.json";
import translationDE from "../locales/de/translation.json";

const resources = {
  pl: {
    translation: translationPL
  },
  de: {
    translation: translationDE
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "pl",
    fallbackLng: "pl",
    // have a common namespace used around the full app
    //ns: ["translations"],
    //defaultNS: "translations",
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
      useSuspense: false
    },
  })
export default i18n