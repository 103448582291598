import React from "react";
import { Col } from "react-bootstrap";
import "./styles.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBriefcase, faEdit, faComments, faGlobe, faSpellCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

library.add(faBriefcase, faEdit, faComments, faGlobe, faSpellCheck);

export default function IconBox({title, description, icon, className, ...rest}) {
  return (
    <Col className={["box-container", className]} data-sal="slide-up" data-sal-delay="200">
      <Col className="box p-0 col-12" {...rest}>
        <div className="beforeBox"/>
        <div className="afterBox"/>
        <div className="content col">
          <div className="box__icon">{<Icon icon={icon}/>}</div>
          <div>
            <h3 className="box__title">{title}</h3>
            <p className="box__description px-md-4">{description}</p>
          </div>
        </div>
      </Col>
    </Col>
  )
}