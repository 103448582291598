import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";
import Heading from "../components/Heading/Heading";
import ImageBox from "../components/ImageBox/ImageBox";
import parse from "html-react-parser";
import boy from "../images/boy.jpg";
import adult from "../images/adult.jpg";
import kid from "../images/school.jpg";

function Learning(){
  const { t } = useTranslation();
  return (
    <Container fluid className="py-sm-4 py-lg-5 px-0 pb-sm-5" id="learning">
      <Container className="pt-4 px-0 pt-lg-0 pb-lg-5 px-lg-0 pb-sm-5">
        <Row className="m-0">
          <Heading data-sal="slide-right">
            <h2 className="m-0">{t("learning.title")}</h2>
          </Heading>
          <Col className="col-12 col-lg-6 px-2 px-sm-4 pt-lg-3">
            <Col className="col py-2 px-4 px-lg-0">
              <p data-sal="slide-up" data-sal-delay="200">
                {parse(t("learning.content"))}
              </p>
            </Col>
          </Col>
          <Col className="col-12 col-lg-6 images px-lg-5 mb-lg-0 px-5 pb-md-5 pt-3">
              <ImageBox image={boy} alt="Goethe Institut" delay="300" side="left" sideStyle="bg--primary text--info"/>
              <ImageBox image={adult} alt="firmy i&nbsp;instytucje" delay="0" side="right" sideStyle="bg--warning text--light"/>
              <ImageBox image={kid} alt="dzieci, młodzież i&nbsp;dorośli" delay="500" side="left" sideStyle="bg--info text--light"/>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default withTranslation()(Learning);