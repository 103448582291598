import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Heading from "../components/Heading/Heading";
import ContactForm from "../components/ContactForm/ContactForm";

function Contact() {
  return (
    <Container fluid className="p-0">
      <Container className="p-0">
        <Row className="m-0">
        <Col 
          className="px-0 py-sm-4 px-lg-4 bg--primary text--light"
          data-sal="slide-right"
          id="contact">
          <div className="pt-lg-4 px-sm-4 px-lg-0 pt-4">
            <Heading>
              <h2 className="m-0">Kontakt</h2>
            </Heading>
            <ContactForm/>
          </div>
        </Col>
      </Row>
      </Container>
    </Container>
  )
}

export default Contact;