import React from "react";
import "./styles.scss";

function Heading({children}) {
  return (
    <div data-sal="slide-right" className="col-12 pt-4 px-5 px-sm-5 pb-4 px-lg-4 pb-lg-2">
      <div className="heading" data-sal="slide-right" data-sal-delay="300">
        <div className="marker marker--left"></div>
        <div className="marker marker--right"></div>
        <div className="heading__content">{children}</div>
      </div>
    </div>
  )
}

export default Heading