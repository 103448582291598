import React from "react";
import "./styles.scss";

function ImageBox(props) {
  return (
    <div className={`imageBox`} data-sal="zoom-in" data-sal-delay={props.delay}>
      <img src={props.image} alt={props.alt}/>
    </div>
  );
}

export default ImageBox;